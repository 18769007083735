const { default: axios } = require("axios");
// const _ = require("lodash");
const { default: Config } = require("./config");

module.exports = {
  getAdminType: () => {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("auth");
      axios
        .get(Config.BACKEND_API + "admin/auth", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          resolve({
            type: res.data.admin.type,
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = '/login'
          }
          reject(err);
        });
    });
  },
};
