import React, { Component } from "react";

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="loader-overlay">
          <div className="loader-wrap">
            <div className="loading-div">
              <div className="loading">
              <img className="loader-logo" src="/img/logo.png"></img>
                <div className="loader-line"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Loader;
