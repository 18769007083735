import { Link } from "react-router-dom";
import React, { Component } from 'react'
import Config from '../../libs/config'
import axios from 'axios';

export default class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMsg: '',
      redirect: false
    }
  }

  componentDidMount() {

  }

  handleLogin = e => {
    e.preventDefault();
    console.log('eee ',Config);
    axios.post(Config.BACKEND_API + 'admin/login', {
      email: e.target.email.value,
      password: e.target.password.value
    }).then( data => {
      console.log('dddd ',this.props);
      localStorage.setItem('auth', data.data.token)
      localStorage.setItem('type', data.data.type)
      // this.props.history.push('/');
      this.setState({
        redirect: true
      })
    }).catch( err => {
      this.setState({
        error: true,
        errorMsg: err.response.data.message
      })
      console.log('Err ',err.response.data.message)
    })
  }

  render() {
    if (this.state.redirect) {
      // return <Redirect to="/" />
    }
    return (
      <div className="container vh-100">

        <div className="row justify-content-center vh-100 align-items-center">
          <div className="col-lg-5 col-md-8 col-12">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">

                <div className="row">
                  <div className="col-12">
                    <div className="px-5 py-5 px-4 py-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Login</h1>
                      </div>
                      {this.state.error && <div className="alert alert-danger" role="alert">
                        {this.state.errorMsg}
                      </div>}
                      <form className="user" onSubmit={this.handleLogin}>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Email Address..."
                            name="email"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-user"
                            id="exampleInputPassword"
                            placeholder="Password"
                            name="password"
                            required
                          />
                        </div>
                        <button type="submit" className="btn btn-dark btn-user btn-block">
                          Login
                        </button>

                      </form>

                      {/* <div className="text-center mt-4">
                        <a className="small">Forgot Password?</a>
                      </div> */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 text-center">
            <Link to="/">
              <a>Go to Dashboard</a>
            </Link>
          </div> */}
          </div>
        </div>
      </div>
    )
  }
}
