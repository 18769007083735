import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";
// import Logo from '/img/logo.png';
import "react-datepicker/dist/react-datepicker.css";
import {
  engine,
  suspension,
  electronic,
  interior,
  tire,
  road,
  disclaimer,
} from "../../libs/inspectData";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import axios from "axios";
import Config from "../../libs/config";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

let array = [];

const carData = [
  { name: "Bonnet", box: "0" },
  { name: "Front Bumper", box: "0" },
  { name: "Left Fender", box: "0" },
  { name: "Front Left Door", box: "0" },
  { name: "Rear Left Door", box: "0" },
  { name: "Left Rocker / Sill Panels", box: "0" },
  { name: "Left Quarter Panel", box: "0" },
  { name: "Trunk / Boot Door", box: "0" },
  { name: "Rear Bumper", box: "0" },
  { name: "Right Quarter Panel", box: "0" },
  { name: "Rear Right Door", box: "0" },
  { name: "Front Right Door", box: "0" },
  { name: "Right Rocker / Sill Panels", box: "0" },
  { name: "Right Fender", box: "0" },
  { name: "Car Pillars", box: "0" },
  { name: "Car Roof", box: "0" },
  { name: "Door Locks", box: "0" },
  { name: "All Body Panels Properly Aligned", box: "0" },
  { name: "Rear-view And Side view Mirrors", box: "0" },
  { name: "Windshield", box: "0" },
  { name: "Wiper Blades Front", box: "0" },
  { name: "Wiper Blades Rear", box: "0" },
  { name: "Convertible Top (If Applicable)", box: "0" },
  { name: "Frame rail and sub frame", box: "0" },
  { name: "Fuel Filler Cap", box: "0" },
  { name: "Mud Flaps", box: "0" },
];

const InspectPage = (props) => {
  const [showLink, setShowLink] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const [showKeyFinding, setShowKeyFinding] = useState(false);
  const [showDetailedInspection, setShowDetailedInspection] = useState(false);
  const [yearOfManufacture, setYearOfManufacture] = useState(new Date());
  const [initialInspectData, setInitialInspectData] = useState(null);
  const [showSecurityCheck, setShowSecurityCheck] = useState(false);
  const [securityCode, setSecurityCode] = useState(null);
  const inputEl = useRef();

  let params = useParams();

  useEffect(() => {
    if (params.id) {
      setShowSecurityCheck(true);
    }
  }, [params]);
  // if (params) {
  //   setShowSecurityCheck(true);
  // }

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      pdfLinks: [{ link: "" }],
      YearOfManufacture: new Date().getFullYear(),
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "pdfLinks", // unique name for your Field Array
    }
  );
  const onSubmit = (data) => {
    console.log("ddd ", data);
    setShowLink(true);
    // setPdfLink(data.pdfLinks);
  };

  const handlePrint = useReactToPrint({
    content: () => inputEl.current,
  });

  const onInitialAdd = (data) => {
    console.log("initial add ", data, yearOfManufacture);
    data.YearOfManufacture = yearOfManufacture.getFullYear();
    const adding = axios
      .post(Config.BACKEND_API + "v2/api/car-inspection-reports", data)
      .then((res) => {
        console.log("rrrr ", res);
        setInitialInspectData(res.data.Data);
        setShowKeyFinding(true);
        setShowDetailedInspection(true);
      });
    toast.promise(adding, {
      loading: "Loading...",
      success: "Completed.",
      error: "Uh oh, there was an error!",
    });
  };

  const onDone = (data) => {
    data.YearOfManufacture = yearOfManufacture.getFullYear();
    data.VehicleDiagnosticReportFileName = pdfLink?.Name;
    data.VehicleDiagnosticReportFileUrl = pdfLink?.FileUrl;
    data.CarInspectionReportSections.map((section, sIndex) => {
      data.CarInspectionReportSections[sIndex].Id =
        initialInspectData.CarInspectionReportSections[sIndex].Id;
      section.CarInspectionReportItems.map((item, iIndex) => {
        data.CarInspectionReportSections[sIndex].CarInspectionReportItems[
          iIndex
        ].Id =
          initialInspectData.CarInspectionReportSections[
            sIndex
          ].CarInspectionReportItems[iIndex].Id;
      });
    });
    const adding = axios
      .post(
        Config.BACKEND_API +
          `v2/api/car-inspection-reports/${initialInspectData.Id}/update`,
        data
      )
      .then((res) => {
        console.log("rrrr ", res);
        setInitialInspectData(res.data.Data);
        setShowKeyFinding(true);
        setShowDetailedInspection(true);
      });
    toast.promise(adding, {
      loading: "Loading...",
      success: "Completed.",
      error: "Uh oh, there was an error!",
    });
  };

  const onImageUpload = async (mainItem, carIndex, subItem, index, event) => {
    console.log("ffff ", event.target.files[0]);
    let data = new FormData();
    data.append("CarInspectionId", initialInspectData.Id);
    data.append("CarInspectionItemId", subItem.Id);
    data.append("Files", event.target.files[0]);
    let uploading = axios
      .post(
        Config.BACKEND_API +
          `v2/api/car-inspection-reports/${initialInspectData.Id}/item/${subItem.Id}/files`,
        data
      )
      .then((res) => {
        console.log("rrrr ", res.data.Data);
        setShowDetailedInspection(false);
        let inspectData = initialInspectData;
        inspectData.CarInspectionReportSections[
          carIndex
        ].CarInspectionReportItems[index].CarInspectionReportFiles.push(
          res.data.Data[0]
        );
        setInitialInspectData(inspectData);
        setShowDetailedInspection(true);

        console.log("inspectData ");
        // setInitialInspectData(res.data.Data);
        // setShowKeyFinding(true);
        // setShowDetailedInspection(true);
      });

    toast.promise(uploading, {
      loading: "Uploading...",
      success: "Uploaded.",
      error: "Uh oh, there was an error!",
    });
  };

  const handlePDFUpload = (event) => {
    console.log("ffff ", event.target.files[0]);
    let data = new FormData();
    data.append("CarInspectionId", initialInspectData.Id);
    data.append("Files", event.target.files[0]);
    let uploading = axios
      .post(
        Config.BACKEND_API +
          `v2/api/car-inspection-reports/${initialInspectData.Id}/vehicle-diagnostic-report`,
        data
      )
      .then((res) => {
        console.log("rrrr ", res.data.Data);
        setPdfLink(res.data.Data[0]);
      });

    toast.promise(uploading, {
      loading: "Uploading...",
      success: "Uploaded.",
      error: "Uh oh, there was an error!",
    });
  };

  const onSecurityCheck = async (data) => {
    setSecurityCode(data.securityCode);
    const checking = axios
      .get(
        Config.BACKEND_API +
          `v2/api/car-inspection-reports/${params.id}?Id=${params.id}&SecurityCode=${data.securityCode}`
      )
      .then((res) => {
        console.log("rrrr ", res.data.Data);
        if (res.data.Data) {
          setShowSecurityCheck(false);
          setInitialInspectData(res.data.Data);
          setShowKeyFinding(true);
          setShowDetailedInspection(true);
          setValue("OwnerName", res.data.Data.OwnerName);
          setValue("CarNameAndModel", res.data.Data.CarNameAndModel);
          setValue("YearOfManufacture", res.data.Data.YearOfManufacture);
          setValue("ChassisNumber", res.data.Data.ChassisNumber);
          setValue("PlateNumber", res.data.Data.PlateNumber);
          setValue("Criticalssues", res.data.Data.Criticalssues);
          setValue("OptionalItems", res.data.Data.OptionalItems);
          setValue("NumberOfKeys", res.data.Data.NumberOfKeys);
          setValue(
            "VehicleDiagnosticReportFileName",
            res.data.Data.VehicleDiagnosticReportFileName
          );
          setValue(
            "VehicleDiagnosticReportFileUrl",
            res.data.Data.VehicleDiagnosticReportFileUrl
          );
          setValue("DateOfInspection", new Date(res.data.Data.DateOfInspection));
          setValue("Location", res.data.Data.Location);
          setValue("TechnicianName", res.data.Data.TechnicianName);
          setValue(
            "CarInspectionReportSections",
            res.data.Data.CarInspectionReportSections
          );
          setYearOfManufacture(new Date(res.data.Data.YearOfManufacture));
          setPdfLink({
            Name: res.data.Data.VehicleDiagnosticReportFileName,
            FileUrl: res.data.Data.VehicleDiagnosticReportFileUrl,
          });
        } else {
          throw new Error("Invalid Security Code");
        }
      });
    toast.promise(checking, {
      loading: "Checking...",
      success: "Completed.",
      error: "Invalid Security Code",
    });
  };

  const textAreaAdjust = (ele) => {
    ele.target.style.height = "inherit";
    ele.target.style.height = `${ele.target.scrollHeight}px`;
  };

  return (
    <div>
      <div ref={inputEl}>
        <nav
          className="navbar navbar-dark"
          style={{ backgroundColor: "#2a72bb" }}
        >
          <div
            className="navbar-brand p-2"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/logo.png"
              width={175}
              height="70"
              className="p-2"
              alt="logo"
            />
          </div>
        </nav>
        {showSecurityCheck ? (
          <div className="container">
            <form>
              <h2 className="mt-5 mb-2">{` FULL CAR INSPECTION REPORT`}</h2>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Security Code</label>
                  <input
                    type="password"
                    className="form-control"
                    id="name"
                    placeholder="Enter your security code"
                    {...register("securityCode", { required: true })}
                  />
                </div>

                <button
                  className="btn btn-info text-white w-100"
                  style={{ backgroundColor: "#2a72bb", color: "#fff" }}
                  // type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit(onSecurityCheck)}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="container">
            <form>
              <h2 className="mt-5 mb-2">{` FULL CAR INSPECTION REPORT`}</h2>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Owner Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter car owner name"
                    {...register("OwnerName", { required: true })}
                  />
                </div>
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Car Name & Model</label>
                  <input
                    type="text"
                    className="form-control"
                    id="model"
                    placeholder="Enter car name and model"
                    {...register("CarNameAndModel", { required: true })}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Year of Manufacture</label>
                  {/* <Controller
                  control={control}
                  name="YearOfManufacture"
                  rules={{ required: false }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => ( */}
                  <DatePicker
                    selected={yearOfManufacture}
                    showYearPicker
                    dateFormat="yyyy"
                    className="form-control"
                    onChange={(date) => {
                      console.log("dateeee ", date.getFullYear());
                      setYearOfManufacture(date);
                    }}
                    // selected={new Date(value)}
                    // value={Date(value, 1, 1)}
                  />
                  {/* )}
                /> */}
                </div>
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Chassis Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="chassis"
                    placeholder="Enter chassis number"
                    {...register("ChassisNumber", { required: false })}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                <div className="form-group col-lg-12 col-md-12 col-sm-12 p-2">
                  <label htmlFor="plate">Plate Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="plate"
                    placeholder="Enter plate number"
                    {...register("PlateNumber", { required: true })}
                  />
                </div>
              </div>

              {!showKeyFinding && (
                <button
                  className="btn btn-info text-white w-100"
                  style={{ backgroundColor: "#2a72bb", color: "#fff" }}
                  // type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit(onInitialAdd)}
                >
                  Add
                </button>
              )}

              {showKeyFinding && (
                <>
                  <h3 className="mt-5">KEY FINDINGS:</h3>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="critical">Critical Issues</label>
                      <textarea
                        className="form-control"
                        id="critical"
                        rows="3"
                        placeholder="Enter critical issues"
                        {...register("Criticalssues", { required: false })}
                      />
                    </div>
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="optional">Optional Items</label>
                      <textarea
                        className="form-control"
                        id="optional"
                        rows="3"
                        placeholder="Enter optional items"
                        {...register("OptionalItems")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="keys">Number of Keys Available</label>
                      <select
                        className="form-control"
                        id="keys"
                        {...register("NumberOfKeys")}
                      >
                        <option>Select number of keys available</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="pdf">
                        Vehicle Diagnostic Report (PDF LINK):
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="pdf"
                        placeholder="Enter vehicle diagnostic report (PDF Link)"
                        onChange={handlePDFUpload}
                        // {...register("VehicleDiagnosticReportFileUrl")}
                        //
                      />
                      {pdfLink && <small>PDF Added</small>}
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="info">
                      All information given in this report is valid and correct
                      as of the date of the inspection.
                    </label>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="date">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        id="date"
                        {...register("DateOfInspection")}
                      />
                    </div>
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="location">Location</label>
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        placeholder="Enter location"
                        {...register("Location")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                    <div className="form-group col-lg-12 col-md-12 col-sm-12 p-2">
                      <label htmlFor="tech">Technician name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tech"
                        placeholder="Enter technician name"
                        {...register("TechnicianName")}
                      />
                    </div>
                  </div>
                </>
              )}

              {showDetailedInspection && (
                <>
                  <h3 className="mt-5">DETAILED INSPECTION FINDINGS</h3>
                  {initialInspectData &&
                    initialInspectData.CarInspectionReportSections.map(
                      (car, carIndex) => (
                        <table className="table table-hover p-2 w-100">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ width: "35%" }}
                              >
                                {car.Name}
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ width: "35%" }}
                              >
                                Comments
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ background: "green", width: "10%" }}
                              >
                                Passed
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ background: "yellow", width: "10%" }}
                              >
                                Ok
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ background: "red", width: "10%" }}
                              >
                                Failed
                              </th>
                            </tr>
                          </thead>
                          {car.CarInspectionReportItems.map((e, index) => (
                            <tbody key={index}>
                              <tr>
                                <td className="text-center">
                                  {e.Name}
                                  <ul className="text-left">
                                    {e.CarInspectionReportFiles.map(
                                      (files, fileIndex) => {
                                        return (
                                          <li key={fileIndex}>
                                            <a
                                              href={files.FileUrl}
                                              target="_blank"
                                              download
                                            >
                                              Item Add
                                            </a>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </td>
                                <td>
                                  <textarea
                                    className="form-control"
                                    onKeyUp={textAreaAdjust}
                                    id="comments"
                                    {...register(
                                      `CarInspectionReportSections.${carIndex}.CarInspectionReportItems.${index}.Comment`,
                                      {
                                        value:
                                          initialInspectData
                                            .CarInspectionReportSections[
                                            carIndex
                                          ].CarInspectionReportItems[index]
                                            .Comment || null,
                                      }
                                    )}
                                  />
                                </td>
                                <td
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      // name="exampleRadios"
                                      // id="exampleRadios1"
                                      value="passed"
                                      {...register(
                                        `CarInspectionReportSections.${carIndex}.CarInspectionReportItems.${index}.Value`
                                      )}
                                    />
                                  </div>
                                </td>
                                <td
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      // name="exampleRadios"
                                      // id="exampleRadios2"
                                      value="ok"
                                      {...register(
                                        `CarInspectionReportSections.${carIndex}.CarInspectionReportItems.${index}.Value`
                                      )}
                                    />
                                  </div>
                                </td>
                                <td
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      // name="exampleRadios"
                                      // id="exampleRadios3"
                                      value="failed"
                                      {...register(
                                        `CarInspectionReportSections.${carIndex}.CarInspectionReportItems.${index}.Value`
                                      )}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>
                                  <input
                                    type="file"
                                    accept="audio/*,video/*,image/*"
                                    onChange={(fileEvent) =>
                                      onImageUpload(
                                        car,
                                        carIndex,
                                        e,
                                        index,
                                        fileEvent
                                      )
                                    }
                                  />
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      )
                    )}
                  <button
                    className="btn btn-info text-white w-100"
                    style={{ backgroundColor: "#2a72bb", color: "#fff" }}
                    // type="submit"
                    disabled={isSubmitting}
                    onClick={handleSubmit(onDone)}
                  >
                    Save
                  </button>
                </>
              )}

              {/* <button
              type="button"
              className="btn btn-secondary ml-2"
              onClick={handleSubmit(onDone)}
            >
              Done
            </button> */}
            </form>
            {/* <div className="row">
            <div className="col-6">
              {showLink ? (
                <>
                  <h5>PDF Links</h5>
                  <ul>
                    {pdfLink.map((pdf) => {
                      return (
                        <li className="mb-2">
                          <a href={pdf.link}>
                            <button className="btn btn-primary">
                              Download Report Link
                            </button>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h5>PDF Links</h5>
                  {fields.map((field, index) => (
                    <div className="form-group d-flex">
                      <input
                        className="form-control"
                        type="url"
                        key={field.id} // important to include key with field's id
                        {...register(`pdfLinks.${index}.link`)}
                      />
                      <button
                        type="button"
                        className="btn btn-secondary ml-2"
                        onClick={() => remove(index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button type="submit" className="btn btn-primary mr-2">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => append({ link: "" })}
                  >
                    Add more Link
                  </button>
                </form>
              )}
            </div>
          </div> */}

            {/* <div className="p-2">
              <label htmlFor="files">Attach Images for Reference |</label>
              <input
                type="file"
                name="images[]"
                id="images"
                accept="image/*"
                multiple="multiple"
                onChange={onSelectFile}
              />
            </div>
            {show && (
              <div className="grid">
                {array.map((item, index) => (
                  <div key={index} className="column">
                    <img src={item.img} key={index} width="330" height="300" />
                  </div>
                ))}
              </div>
            )} */}

            <div className="form-group p-2 mt-1">
              <label htmlFor="info">
                NOTE: ALL IMAGES ARE TAKEN ON THE DATE OF THE REPORT
              </label>
            </div>
            <h3 className="mt-5">DISCLAIMER</h3>
            <div className="form-group p-2 mt-1">
              <ul>
                {disclaimer.map((item, index) => (
                  <li
                    key={index}
                    className="p-2"
                    htmlFor="info"
                  >{` ${item.point}`}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="p-5 mt-2 container">
        {/* <button
          className="btn btn-info text-white w-100"
          style={{ backgroundColor: "#2a72bb", color: "#fff" }}
          // type="submit"
          onClick={handleSubmit(onDone)}
        >
          Save
        </button> */}
        {/* <Pdf targetRef={inputEl} filename={`${owner}_${car}_${Moment().format("DD/MM/YYYY hh:mm a")}.pdf`} options={{orientation: 'portrait',unit: 'in',format: [8.26,11.69 + (lent)]}}>
              {({ toPdf }) => <button className="btn" onClick={toPdf}>Save PDF</button>}
            </Pdf> */}
      </div>
    </div>
  );
};

export default InspectPage;

// w - 8.26 , h - 11.69 = A4 Sheet Size in Inches
