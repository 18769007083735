import Layout from "../layout/mainlayout/Layout.js";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "../../libs/config.js";
import Chart from "react-apexcharts";
import { getAdminType } from "../../libs/auth.js";
import Loader from "../layout/loader/loader.js";

class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      movies: [],
      commissionPercentageList: [],
      totalPurchase: [],
      columnsTwo: [],
      bulkMovieList: [],
      chartData: {},
      isChartDataSet: false,
      isMinutesChartDataSet: false,
      adminType: null,
      loaderLoading: false
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("auth");
    if (token) {
      getAdminType()
        .then((admin) => {         

          this.setState({
            adminType: admin.type
          })
        })
      axios
        .get(Config.BACKEND_API + "admin/analytics/movie-price/list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          this.setState({
            columns: [
              {
                dataField: "name",
                text: "Movie Name",
                searchable: true,
              },
              {
                dataField: "price",
                text: "Price",
                searchable: true,
                formatter: this.pricecheck,
              },
            ],
            movies: res.data.movies,
           
          });
        });
      axios
        .get(
          Config.BACKEND_API + "admin/analytics/commission-percentage/list",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {        
          this.setState({
            commissionPercentageList: res.data.movies,
           
          });
        });

      axios
        .get(Config.BACKEND_API + "admin/analytics/all-admin/list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          this.setState({
            totalCategories: res.data.totalCategories,
            totalMovies: res.data.totalMovies,
            totalProduers: res.data.totalProduers,
            totalUsers: res.data.totalUsers,
          });
        });

      axios
        .get(Config.BACKEND_API + "admin/analytics/movie/amount/list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("totalpurchase", res);
          this.setState({
            columnsTwo: [
              {
                dataField: "_id.name",
                text: "Movie Name",
                searchable: true,
              },
              {
                dataField: "total",
                text: "total",
                searchable: true,
              },
            ],
            totalPurchase: res.data.movieData,
          });
        });

      axios
        .get(Config.BACKEND_API + "admin/analytics/bulk-movie", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {       
          if (res) {
            this.setState({ bulkMovieList: res.data.movies, loaderLoading: false });
            this.createChartData(res.data.movies);
            this.createChartDataMinutes(res.data.movies);
          }
        })

        .catch(({ response }) => {
          if (response?.status === 401) {
            this.props.history.push("/login");
            this.setState({
              loaderLoading: false
            })
          }
        });
    } else {
      this.props.history.push("/login");
    }
  }

  createChartData = (bulkMovies) => {
    let xAxisCategory = bulkMovies.map((item) => item.name);
    let yAxisData = bulkMovies.map((item) => item.views);
    let chartData = {
      options: {
        chart: {
          id: "totalViews",
        },
        xaxis: { categories: xAxisCategory },
      },
      series: [
        {
          name: "views",
          data: yAxisData,
        },
      ],
    };
    this.setState({ chartData: chartData, isChartDataSet: true });
  };

  createChartDataMinutes = (bulkMovies) => {
    let xAxisCategory = bulkMovies.map((item) => item.name);
    let yAxisData = bulkMovies.map((item) => item.watchMinutes);
    let chartData = {
      options: {
        chart: {
          id: "totalViews",
        },
        xaxis: { categories: xAxisCategory },
      },
      series: [
        {
          name: "views",
          data: yAxisData,
        },
      ],
    };
    this.setState({ chartDataMinutes: chartData, isMinutesChartDataSet: true });
  };

  pricecheck = (price, row) => {
    console.log("price", price);

    return (
      <>
        {typeof price === "undefined" ? (
          <span className="text-info font-weight-bold">Free</span>
        ) : (
          <span className="font-weight-bold">{price}</span>
        )}
      </>
    );
  };

  render() {
    return (
      <>
        <Layout>
          <h1>Dashboard</h1>
        </Layout>
      </>
    );
  }
}

export default DashBoard;
