import React, { Component } from "react";
import _ from "lodash";
import { getAdminType, getAllPermissions } from "../../../libs/auth";
import NavDropdownItem from "./NavDropdownItem";
import { Link } from "react-router-dom";

export default class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
      active: "",
      toggled: false,
      modules: [],
      adminType: "",
    };
  }

  async componentDidMount() {
    let path = window.location.pathname;
    let file = path.split("/");
    console.log("ffffff ", file);
    let admin = await getAdminType();
    this.setState({
      path,
      active: file[1],
      adminType: admin.type,
    });
  }

  render() {
    console.log(
      "this.state.adminTypethis.state.adminType ",
      this.state.adminType
    );
    return (
      <ul
        className={`navbar-nav bg-gradient-gradient sidebar sidebar-light accordion ${
          this.props.mainToggled && "toggled"
        }`}
        id="accordionSidebar"
      >
        {/* Sidebar - Brand */}
        <a className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-text sidbar-logo mx-3">
            {" "}
            <img src="/img/logo.png"></img>{" "}
          </div>
        </a>
        {/* Divider */}
        {/* <hr className="sidebar-divider my-0" /> */}
        {/* Nav Item - Dashboard */}
        <Link to="/">
          <li className={`nav-item ${this.state.active === "" && "active"}`}>
            <a className="nav-link">
              <i className="fas fa-film" />
              <span>Dashboard</span>
            </a>
          </li>
        </Link>

        {this.state.adminType === "admin" && (
          <Link to="/users">
            <li
              className={`nav-item ${
                this.state.active === "users" && "active"
              }`}
            >
              <a className="nav-link">
                <i className="fas fa-language" />
                <span>Users</span>
              </a>
            </li>
          </Link>
        )}

        {this.state.adminType === "admin" &&  (
          <Link to="/restaurant">
            <li
              className={`nav-item ${
                this.state.active === "restaurant" && "active"
              }`}
            >
              <a className="nav-link">
                <i className="fas fa-user-lock" />
                <span>Restaurant</span>
              </a>
            </li>
          </Link>
        )}

        

        {this.state.adminType === "admin" &&  (
          <Link to="/admin">
            <li
              className={`nav-item ${
                this.state.active === "admin" && "active"
              }`}
            >
              <a className="nav-link">
                <i className="fas fa-user-lock" />
                <span>Admin</span>
              </a>
            </li>
          </Link>
        )}

        <div className="text-center d-none d-md-inline">
          <button
            className="bg-transparent border-0"
            id="sidebarToggle"
            onClick={() => this.props.handleMainToggle()}
          />
        </div>
      </ul>
    );
  }
}
