import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";
// import Logo from '/img/logo.png';
import "react-datepicker/dist/react-datepicker.css";
import {
  engine,
  suspension,
  electronic,
  interior,
  tire,
  road,
  disclaimer,
} from "../../libs/inspectData";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import axios from "axios";
import Config from "../../libs/config";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import ImageViewer from 'react-simple-image-viewer';
import {FaRegCheckSquare, FaRegSquare} from 'react-icons/fa';

let array = [];

const carData = [
  { name: "Bonnet", box: "0" },
  { name: "Front Bumper", box: "0" },
  { name: "Left Fender", box: "0" },
  { name: "Front Left Door", box: "0" },
  { name: "Rear Left Door", box: "0" },
  { name: "Left Rocker / Sill Panels", box: "0" },
  { name: "Left Quarter Panel", box: "0" },
  { name: "Trunk / Boot Door", box: "0" },
  { name: "Rear Bumper", box: "0" },
  { name: "Right Quarter Panel", box: "0" },
  { name: "Rear Right Door", box: "0" },
  { name: "Front Right Door", box: "0" },
  { name: "Right Rocker / Sill Panels", box: "0" },
  { name: "Right Fender", box: "0" },
  { name: "Car Pillars", box: "0" },
  { name: "Car Roof", box: "0" },
  { name: "Door Locks", box: "0" },
  { name: "All Body Panels Properly Aligned", box: "0" },
  { name: "Rear-view And Side view Mirrors", box: "0" },
  { name: "Windshield", box: "0" },
  { name: "Wiper Blades Front", box: "0" },
  { name: "Wiper Blades Rear", box: "0" },
  { name: "Convertible Top (If Applicable)", box: "0" },
  { name: "Frame rail and sub frame", box: "0" },
  { name: "Fuel Filler Cap", box: "0" },
  { name: "Mud Flaps", box: "0" },
];

const ViewReport = (props) => {
  const [showLink, setShowLink] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const [showKeyFinding, setShowKeyFinding] = useState(false);
  const [showDetailedInspection, setShowDetailedInspection] = useState(false);
  const [yearOfManufacture, setYearOfManufacture] = useState(new Date());
  const [initialInspectData, setInitialInspectData] = useState(null);
  const [showSecurityCheck, setShowSecurityCheck] = useState(false);
  const [securityCode, setSecurityCode] = useState(null);
  const [previewImages, setPreviewImages] = useState(null)
  const inputEl = useRef();

  let params = useParams();

  useEffect(() => {
    if (params.id) {
      setShowSecurityCheck(true);
    }
  }, [params]);
  // if (params) {
  //   setShowSecurityCheck(true);
  // }

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      pdfLinks: [{ link: "" }],
      YearOfManufacture: new Date().getFullYear(),
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "pdfLinks", // unique name for your Field Array
    }
  );

  const onSecurityCheck = async (data) => {
    setSecurityCode(data.securityCode);
    const checking = axios
      .get(
        Config.BACKEND_API +
          `v2/api/car-inspection-reports?Id=${params.id}&SecurityCode=${data.securityCode}`
      )
      .then((res) => {
        console.log("rrrr ", res.data.Data);
        if (res.data.Data) {
          setShowSecurityCheck(false);
          setInitialInspectData(res.data.Data);
          setShowKeyFinding(true);
          setShowDetailedInspection(true);
          setValue("OwnerName", res.data.Data.OwnerName);
          setValue("CarNameAndModel", res.data.Data.CarNameAndModel);
          setValue("YearOfManufacture", res.data.Data.YearOfManufacture);
          setValue("ChassisNumber", res.data.Data.ChassisNumber);
          setValue("PlateNumber", res.data.Data.PlateNumber);
          setValue("Criticalssues", res.data.Data.Criticalssues);
          setValue("OptionalItems", res.data.Data.OptionalItems);
          setValue("NumberOfKeys", res.data.Data.NumberOfKeys);
          setValue(
            "VehicleDiagnosticReportFileName",
            res.data.Data.VehicleDiagnosticReportFileName
          );
          setValue(
            "VehicleDiagnosticReportFileUrl",
            res.data.Data.VehicleDiagnosticReportFileUrl
          );
          setValue("DateOfInspection", res.data.Data.DateOfInspection);
          setValue("Location", res.data.Data.Location);
          setValue("TechnicianName", res.data.Data.TechnicianName);
          setValue(
            "CarInspectionReportSections",
            res.data.Data.CarInspectionReportSections
          );
          setYearOfManufacture(res.data.Data.YearOfManufacture);
        } else {
          throw new Error("Invalid Security Code");
        }
      });
    toast.promise(checking, {
      loading: "Checking...",
      success: "Completed.",
      error: "Invalid Security Code",
    });
  };

  const textAreaAdjust = (ele) => {
    ele.target.style.height = "inherit";
    ele.target.style.height = `${ele.target.scrollHeight}px`;
  };

  return (
    <div>
      <div ref={inputEl}>
        <nav
          className="navbar navbar-dark"
          style={{ backgroundColor: "#2a72bb" }}
        >
          <div
            className="navbar-brand p-2"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/logo.png"
              width={175}
              height="70"
              className="p-2"
              alt="logo"
            />
          </div>
        </nav>
        {showSecurityCheck ? (
          <div className="container">
            <form>
              <h2 className="mt-5 mb-2">{` FULL CAR INSPECTION REPORT`}</h2>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Security Code</label>
                  <input
                    type="password"
                    className="form-control"
                    id="name"
                    placeholder="Enter your security code"
                    {...register("securityCode", { required: true })}
                  />
                </div>

                <button
                  className="btn btn-info text-white w-100"
                  style={{ backgroundColor: "#2a72bb", color: "#fff" }}
                  // type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit(onSecurityCheck)}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="container">
            <form>
              <h2 className="mt-5 mb-2">{` FULL CAR INSPECTION REPORT`}</h2>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Owner Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter car owner name"
                    disabled
                    {...register("OwnerName", { required: true })}
                  />
                </div>
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Car Name & Model</label>
                  <input
                    type="text"
                    className="form-control"
                    id="model"
                    placeholder="Enter car name and model"
                    disabled
                    {...register("CarNameAndModel", { required: true })}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Year of Manufacture</label>
                  <input
                    type="text"
                    className="form-control"
                    id="chassis"
                    placeholder="Enter chassis number"
                    disabled
                    value={yearOfManufacture}
                    // {...register("ChassisNumber", { required: false })}
                  />
                  {/* <Controller
                  control={control}
                  name="YearOfManufacture"
                  rules={{ required: false }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => ( */}
                  {/* <DatePicker
                    selected={yearOfManufacture}
                    showYearPicker
                    dateFormat="yyyy"
                    className="form-control"
                    disabled
                    onChange={(date) => {
                      console.log("dateeee ", date.getFullYear());
                      setYearOfManufacture(date);
                    }}
                    // selected={new Date(value)}
                    // value={Date(value, 1, 1)}
                  /> */}
                  {/* )}
                /> */}
                </div>
                <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                  <label>Chassis Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="chassis"
                    placeholder="Enter chassis number"
                    disabled
                    {...register("ChassisNumber", { required: false })}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                <div className="form-group col-lg-12 col-md-12 col-sm-12 p-2">
                  <label htmlFor="plate">Plate Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="plate"
                    placeholder="Enter plate number"
                    disabled
                    {...register("PlateNumber", { required: true })}
                  />
                </div>
              </div>

              {showKeyFinding && (
                <>
                  <h3 className="mt-5">KEY FINDINGS:</h3>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="critical">Critical Issues</label>
                      <textarea
                        className="form-control"
                        id="critical"
                        rows="3"
                        placeholder="Enter critical issues"
                        disabled
                        {...register("Criticalssues", { required: false })}
                      />
                    </div>
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="optional">Optional Items</label>
                      <textarea
                        className="form-control"
                        id="optional"
                        rows="3"
                        placeholder="Enter optional items"
                        disabled
                        {...register("OptionalItems")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="keys">Number of Keys Available</label>
                      <select
                        className="form-control"
                        id="keys"
                        disabled
                        {...register("NumberOfKeys")}
                      >
                        <option>Select number of keys available</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="pdf">
                        Vehicle Diagnostic Report (PDF LINK):
                      </label>
                      {initialInspectData.VehicleDiagnosticReportFileUrl ? (
                        <a
                          href={
                            initialInspectData.VehicleDiagnosticReportFileUrl
                          }
                          target="_blank"
                          download
                        >
                          View PDF
                        </a>
                      ): <p>Not PDF Attached</p>}

                      
                      {pdfLink && <small>PDF Added</small>}
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="info">
                      All information given in this report is valid and correct
                      as of the date of the inspection.
                    </label>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="date">Date</label>
                      {/* <p>{new Date(initialInspectData.DateOfInspection).toLocaleDateString('en-GB')}</p> */}
                      <input
                        type="text"
                        className="form-control"
                        id="date"
                        disabled
                        value={new Date(initialInspectData.DateOfInspection).toLocaleDateString('en-GB')}
                      />
                      {/* <input
                        type="date"
                        className="form-control"
                        id="date"
                        disabled
                        {...register("DateOfInspection")}
                      /> */}
                    </div>
                    <div className="form-group col-lg-6 col-md-12 col-sm-12 p-2">
                      <label htmlFor="location">Location</label>
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        placeholder="Enter location"
                        disabled
                        {...register("Location")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 row">
                    <div className="form-group col-lg-12 col-md-12 col-sm-12 p-2">
                      <label htmlFor="tech">Technician name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tech"
                        placeholder="Enter technician name"
                        disabled
                        {...register("TechnicianName")}
                      />
                    </div>
                  </div>
                </>
              )}

              {showDetailedInspection && (
                <>
                  <h3 className="mt-5">DETAILED INSPECTION FINDINGS</h3>
                  {initialInspectData &&
                    initialInspectData.CarInspectionReportSections.map(
                      (car, carIndex) => (
                        <table className="table table-hover p-2 w-100">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ width: "35%" }}
                              >
                                {car.Name}
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ width: "35%" }}
                              >
                                Comments
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ background: "green", width: "10%" }}
                              >
                                Passed
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ background: "yellow", width: "10%" }}
                              >
                                Ok
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                style={{ background: "red", width: "10%" }}
                              >
                                Failed
                              </th>
                            </tr>
                          </thead>
                          {car.CarInspectionReportItems.map((e, index) => (
                            <tbody key={index}>
                              <tr>
                                <td className="text-center">
                                  {e.Name}
                                  <ul className="text-left">
                                    {e.CarInspectionReportFiles.map(
                                      (files, fileIndex) => {
                                        return (
                                          <li key={fileIndex}>
                                            <a
                                            //   href={files.FileUrl}
                                            //   target="_blank"
                                            //   download
                                            onClick={() => setPreviewImages([files.FileUrl])}
                                            style={{color: "blue"}}
                                            >
                                              View
                                            </a>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </td>
                                <td>
                                  <textarea
                                    className="form-control"
                                    onKeyUp={textAreaAdjust}
                                    id="comments"
                                    disabled
                                    {...register(
                                      `CarInspectionReportSections.${carIndex}.CarInspectionReportItems.${index}.Comment`,
                                      {
                                        value:
                                          initialInspectData
                                            .CarInspectionReportSections[
                                            carIndex
                                          ].CarInspectionReportItems[index]
                                            .Comment || null,
                                      }
                                    )}
                                  />
                                </td>
                                <td
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="form-check">
                                    {initialInspectData
                                            .CarInspectionReportSections[
                                            carIndex
                                          ].CarInspectionReportItems[index]
                                            .Value == "passed" ? <FaRegCheckSquare /> : <FaRegSquare />}
                                    {/* <FaRegCheckSquare />
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      // name="exampleRadios"
                                      // id="exampleRadios1"
                                      value="passed"
                                      disabled
                                      {...register(
                                        `CarInspectionReportSections.${carIndex}.CarInspectionReportItems.${index}.Value`
                                      )}
                                    /> */}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="form-check">
                                  {initialInspectData
                                            .CarInspectionReportSections[
                                            carIndex
                                          ].CarInspectionReportItems[index]
                                            .Value == "ok" ? <FaRegCheckSquare /> : <FaRegSquare />}
                                    {/* <input
                                      className="form-check-input"
                                      type="radio"
                                      // name="exampleRadios"
                                      // id="exampleRadios2"
                                      value="ok"
                                      disabled
                                      {...register(
                                        `CarInspectionReportSections.${carIndex}.CarInspectionReportItems.${index}.Value`
                                      )}
                                    /> */}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="form-check">
                                    {initialInspectData
                                            .CarInspectionReportSections[
                                            carIndex
                                          ].CarInspectionReportItems[index]
                                            .Value == "failed" ? <FaRegCheckSquare /> : <FaRegSquare />}
                                    {/* <input
                                      className="form-check-input"
                                      type="radio"
                                      // name="exampleRadios"
                                      // id="exampleRadios3"
                                      value="failed"
                                      disabled
                                      {...register(
                                        `CarInspectionReportSections.${carIndex}.CarInspectionReportItems.${index}.Value`
                                      )}
                                    /> */}
                                  </div>
                                </td>
                              </tr>
                              
                            </tbody>
                          ))}
                        </table>
                      )
                    )}
                  <button
                    className="btn btn-info text-white w-100"
                    style={{ backgroundColor: "#2a72bb", color: "#fff" }}
                    // type="submit"
                    disabled={isSubmitting}
                    // onClick={handleSubmit(onDone)}
                  >
                    Save
                  </button>
                </>
              )}

             
            </form>
        

            <div className="form-group p-2 mt-1">
              <label htmlFor="info">
                NOTE: ALL IMAGES ARE TAKEN ON THE DATE OF THE REPORT
              </label>
            </div>
            <h3 className="mt-5">DISCLAIMER</h3>
            <div className="form-group p-2 mt-1">
              <ul>
                {disclaimer.map((item, index) => (
                  <li
                    key={index}
                    className="p-2"
                    htmlFor="info"
                  >{` ${item.point}`}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="p-5 mt-2 container"></div>
      {previewImages && (
        <ImageViewer
          src={ previewImages }
          currentIndex={ 0 }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ () => setPreviewImages(null) }
        />
      )}
    </div>
  );
};

export default ViewReport;
