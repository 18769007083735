import { Component } from "react";
import NavBar from "../navbar/NavBar";
import SideBar from "../sidebar/SideBar";

class Layout extends Component {   

  state = {
    mainToggled: false
  }

  handleMainToggle = () => {
    let mainToggled = this.state.mainToggled;
    this.setState({
      mainToggled: !mainToggled
    })
  }

  render() {
    return (
      <div id="wrapper">
        <SideBar mainToggled={this.state.mainToggled} handleMainToggle={this.handleMainToggle} />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <NavBar handleMainToggle={this.handleMainToggle} />
            <div className="container-fluid">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
