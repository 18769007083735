import React, { useState } from "react";
import "./assets/css/admin.min.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DashBoard from "./component/pages/DashBoard";
import Login from "./component/pages/Login";
import InspectPage from "./component/pages/InspectPage";
import { Toaster } from "react-hot-toast";
import ViewReport from "./component/pages/ViewReport";

// import { getToken,  } from "./libs/firebase";

function App() {
  // const [isTokenFound, setTokenFound] = useState(false);

  // getToken(setTokenFound)

  return (
    <>
      <ToastContainer />
      <div>
        <Toaster />
      </div>
      <Router>
        <Routes>
          <Route path="inspect">
            <Route path="update/:id" element={<InspectPage />}></Route>
            <Route path="" element={<InspectPage />} />
          </Route>
          <Route path="report">
            <Route path=":id" element={<ViewReport />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
