export const carData = [
    {name: 'Bonnet', box: '0'},
    {name: 'Front Bumper', box: '0'},
    {name: 'Left Fender', box: '0'},
    {name: 'Front Left Door', box: '0'},
    {name: 'Rear Left Door', box: '0'},
    {name: 'Left Rocker / Sill Panels', box: '0'},
    {name: 'Left Quarter Panel', box: '0'},
    {name: 'Trunk / Boot Door', box: '0'},
    {name: 'Rear Bumper', box: '0'},
    {name: 'Right Quarter Panel', box: '0'},
    {name: 'Rear Right Door', box: '0'},
    {name: 'Front Right Door', box: '0'},
    {name: 'Right Rocker / Sill Panels', box: '0'},
    {name: 'Right Fender', box: '0'},
    {name: 'Car Pillars', box: '0'},
    {name: 'Car Roof', box: '0'},
    {name: 'Door Locks', box: '0'},
    {name: 'All Body Panels Properly Aligned', box: '0'},
    {name: 'Rear-view And Side view Mirrors', box: '0'},
    {name: 'Windshield', box: '0'},
    {name: 'Wiper Blades Front', box: '0'},
    {name: 'Wiper Blades Rear', box: '0'},
    {name: 'Convertible Top (If Applicable)', box: '0'},
    {name: 'Frame rail and sub frame', box: '0'},
    {name: 'Fuel Filler Cap', box: '0'},
    {name: 'Mud Flaps', box: '0'}
  ];
  
  export const engine = [
    {name: 'Engine oil'},
    {name: 'Engine oil leak'},
    {name: 'Engine compartment firewall'},
    {name: 'Engine Fan(s)'},
    {name: 'Brake Oil'},
    {name: 'Clutch Oil'},
    {name: 'Clutch Oil leak'},
    {name: 'Gear Oil'},
    {name: 'Gear Oil Leak'},
    {name: 'Coolant & Brake Hoses'},
    {name: 'Battery & Battery cables'},
    {name: 'Belts & pulleys'},
    {name: 'Transmission oil leak'},
    {name: 'Exhaust System'}
  ];
  
  export const suspension = [
    {name: 'Chassis'},
    {name: 'Under Body Shields'},
    {name: 'Suspension'},
    {name: 'Shock Absorbers'},
    {name: 'Air Spring'},
    {name: 'Tie Rods'},
    {name: 'Steering Rack'},
    {name: 'Shock Mount'},
    {name: 'Shock Boot'},
    {name: 'Engine Mount'},
    {name: 'Bushes'},
    {name: 'Transfer case'},
    {name: 'Differential'},
    {name: 'Arms'},
    {name: 'Boots & Mounts'}
  ];
  
  export const electronic = [
    {name: 'Engine'},
    {name: 'Engine Control Unit'},
    {name: 'Transmission'},
    {name: 'Braking System'},
    {name: 'Airbag System'},
    {name: 'Air Conditioning System'},
    {name: 'Instrument Cluster and Warning Lights'},
    {name: 'Head Lights'},
    {name: 'Power Steering'},
    {name: 'Indicators'},
    {name: 'Brake Lights'},
    {name: 'Tail Lights'},
    {name: 'Parking Lights'},
    {name: 'Cruise Control'},
    {name: 'Parking Sensors'},
    {name: 'Reverse Camera'},
    {name: 'Parking Lights'},
    {name: 'Audio Controls'},
    {name: 'Navigation'},
    {name: 'Windows & Sunroof'}
  ];
  
  export const interior = [
    {name: 'Interior Trims'},
    {name: 'Carpets'},
    {name: 'AUpholstery Front Seatsrms'},
    {name: 'Upholstery Rear Seats'},
    {name: 'Audio System'},
    {name: 'Smoke Odour'},
    {
      name: 'Gauges (speedometer, tachometer, temperature, oil pressure, and amp meter)'
    },
    {name: 'Seat Belts'}
  ];
  
  export const tire = [
    {name: 'Matching tires (brand, year, pattern)'},
    {name: 'Car Rims (scratch & dent free)'},
    {name: 'Front brake pads'},
    {name: 'Front brake discs'},
    {name: 'Rear brake pads'},
    {name: 'Rear brake discs'},
    {name: 'All lug nuts in place'},
    {name: 'All trunk tools available'},
    {name: 'Spare tire available in car (where applicable)'},
    {name: 'Front left tire'},
    {name: 'Front right tire'},
    {name: 'Rear left tire'},
    {name: 'Rear right tire'}
  ];
  
  export const road = [
    {name: 'Keyless and / or Remote Start'},
    {name: 'Steering wheel alignment'},
    {name: 'Cruise Control'},
    {name: 'Auto Gear Box Changes'},
    {name: 'Drives straight on level ground'},
    {name: 'Transmission/Clutch'},
    {name: 'Brakes Noise'},
    {name: 'Suspension noises'},
    {name: 'Tyre noise'},
    {name: '4 wheel drive system'},
    {name: 'Engine vibration and noise'},
    {name: 'Interior noise'}
  ];
  
  export const disclaimer = [
    {point: 'Please read the entire report and note any faults identified. '},
    {
      point:
        'Any major faults that could affect your safety should be attended to immediately.'
    },
    {
      point:
        'This inspection is a visual report and no parts are dismantled or removed.'
    },
    {
      point:
        'No responsibility will be taken for failure to identity faults not present or apparent at the time of the inspection.'
    },
    {
      point:
        'This inspection in no way constitutes a guarantee against any future faults that may develop.'
    },
    {
      point:
        'Should any complaints arise following this inspection then we reserve the right to re-inspect the vehicle prior to any rectifications to the vehicle.'
    },
    {
      point:
        'No guarantee is given to the accuracy of the odometer, but any signs of tampering or inaccuracy will be commented on.'
    },
    {
      point:
        'The vehicle air bags are passive restraints triggered by the impact sensors; their function cannot be tested.'
    },
    {
      point:
        'Testing the car body for water leaks requires special facilities and testing equipment, however any signs of water stains and/or damp will be commented on.'
    },
    {
      point:
        'No emissions testing will be conducted due to the need for dedicated equipment.'
    },
    {
      point:
        'If the vehicle has no documentation that shows the cam belt has been changed at the recommended intervals, then we advise a replacement belt is fitted immediately.'
    },
    {
      point:
        'If the vehicle air conditioning refrigerant is low or empty, then we cannot guarantee the air conditioning system is working without faults.'
    },
    {
      point:
        'If the vehicle is fitted with a turbocharger or supercharger and has no gauges to show induction boost, then we are unable to guarantee the system is working to the correct specification. However, the inspector will comment on the performance following a road test if appropriate.'
    },
    {
      point:
        'If an OBD computer scan is conducted then all system faults will be listed. The inspector will not reset the system or clear any historical faults.'
    },
    {
      point:
        'If the vehicle has been run shortly before the inspection, then the inspector will be unable to perform a cold start and identify any potential starting issues.'
    },
    {
      point:
        'An engine cylinder compression test is not possible due to the need for special testing equipment and the time needed to undertake such a test. The inspector will comment if a power imbalance exists due to unequal cylinder performance.'
    },
    {
      point:
        'Automatic and manual four wheel drive systems are tested as far as possible given the area where the inspection is conducted. Traction and differential lock functions cannot be tested fully due to lack of suitable conditions.'
    }
  ];
  